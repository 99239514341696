// @ts-strict-ignore
import React from 'react';
import { mapValues } from 'lodash';
import styled from '@emotion/styled';

import LanguageIcon from '../../assets/icons/language.svg';
import AudioIcon from '../../assets/icons/audio-on.svg';
import FontSizeIcon from '../../assets/icons/font-size.svg';
import SubtitlesIcon from '../../assets/icons/subtitles.svg';
import { useAppDispatch, useAppSelector } from '../../redux';
import { pushOrInsertClassState } from '../../redux/actions/class';
import { Text } from '../../components/text';
import { Button } from '../../components/button';
import { DropdownMenu, DropdownMenuItem } from '../../components/DropdownMenu';
import { UserWithClassId, ErrorPrints, SettingsModalState } from './types';
import { UserStateName } from '../../../types/models';
import { UserState, ClassStateResponse } from '../../../types/routes/class';
import {
  allLanguages,
  offLanguages,
  updateLanguageFormat,
  subtitleFontSizes,
  AccessibilityRecord,
  AnyLanguageFormatRecord,
} from '../../../types/user-state';
import { ConfigDropdown, ConfigDropdownIcon, ConfigDropdownText } from './ClassroomButtons';
import { Spinner } from '../../components/Spinner';

// Format compatible with dropdown menus
const languageOptions: { [name: string]: DropdownMenuItem[] } = {
  subtitles: offLanguages.map((x, index) => ({
    key: index,
    value: x.key,
    name: x.name,
  })),
  spoken: allLanguages.map((x, index) => ({
    key: index,
    value: x.key,
    name: x.name,
  })),
  text: allLanguages.map((x, index) => ({
    key: index,
    value: x.key,
    name: x.name,
  })),
};
const defaultLanguage: { [name: string]: DropdownMenuItem } = {
  spoken: languageOptions.spoken[0],
  text: languageOptions.text[0],
  subtitles: languageOptions.subtitles[0],
};

const accessibilityOptions: DropdownMenuItem[] = subtitleFontSizes.map((x, index) => ({
  key: index,
  value: x,
  name: x,
}));

interface Props {
  classIds?: number[];
  student: UserWithClassId | null;
  errorList: ErrorPrints;
  setSettingsModalState: React.Dispatch<React.SetStateAction<SettingsModalState>>;
}

export const SettingsModal = ({ classIds, student, errorList, setSettingsModalState }: Props) => {
  // Using the query flag for this causes a page reload,
  // obscuring the language setting bug.
  const debugPrint = false;
  const [errorPrints, setErrorPrints] = errorList;
  const dispatch = useAppDispatch();

  const allClassStates = useAppSelector((state) => state.class.classState);
  const theseClassStates: ClassStateResponse[] = allClassStates.filter((cs) => classIds.includes(cs.classId));
  const classStateLoading = useAppSelector((state) => state.class.fetchingClassState || state.class.postingClassState);

  // Copy object so we don't modify defaultLanguage.
  const language = { ...defaultLanguage };
  let accessibility = accessibilityOptions[1];
  for (const thisClassState of theseClassStates) {
    for (const us of thisClassState.userStates) {
      // If user-specific, filter out other users
      // If class-wise, filter out user-specific overrides
      if (student ? us.userId !== null && us.userId !== student.id : us.userId !== null) continue;
      try {
        if (us.name == UserStateName.language) {
          const value = AnyLanguageFormatRecord.check(us.value);
          const languageState = updateLanguageFormat(value);
          for (const sts of Object.keys(languageState)) {
            languageOptions[sts].forEach((x) => {
              if (x.value == languageState[sts]) language[sts] = x;
            });
          }
        } else if (us.name == UserStateName.accessibility) {
          const accessibilityState = AccessibilityRecord.check(us.value);
          accessibility = accessibilityOptions.find((x) => x.value == accessibilityState.font_size);
        }
      } catch (error) {
        const ref = us.name;
        if (!(ref in errorPrints)) {
          const message = `${ref} invalid form ${JSON.stringify(us.value)}`;
          setErrorPrints({ ...errorPrints, [ref]: { message } });
        }
      }
    }
  }

  const selectLanguage = (sts: string, languageSelected: DropdownMenuItem) => {
    if (!classStateLoading) {
      /*
       * This has a separate reset button.
       */
      const value = mapValues(language, (x, key) => (sts == key ? languageSelected.value : x.value));
      const data: UserState[] = [
        {
          userId: student ? student.id : null,
          name: UserStateName.language,
          value,
        },
      ];
      const _classIds = student ? [student.classId] : classIds;
      for (const classId of _classIds)
        dispatch(
          pushOrInsertClassState({
            classId,
            push: true,
            undoOverrides: false,
            data,
          }),
        );
    }
  };

  const selectAccessibility = (optionSelected: DropdownMenuItem) => {
    if (!classStateLoading) {
      const data: UserState[] = [
        {
          userId: student ? student.id : null,
          name: UserStateName.accessibility,
          value: { font_size: optionSelected.value },
        },
      ];
      const _classIds = student ? [student.classId] : classIds;
      for (const classId of _classIds)
        dispatch(
          pushOrInsertClassState({
            classId,
            push: true,
            undoOverrides: false,
            data,
          }),
        );
    }
  };

  const doDefault = () => {
    if (!classStateLoading)
      for (const thisClassState of theseClassStates) {
        const classId = thisClassState.classId;
        const data: UserState[] = [];
        /*
         * For the class: reset any class-wise values and
         * student-specific overrides.
         * For the student: reset any student-specific values
         */
        for (const name of [UserStateName.language, UserStateName.accessibility]) {
          data.push({
            userId: student ? student.id : null,
            name,
            value: undefined,
          });
        }
        dispatch(
          pushOrInsertClassState({
            classId,
            push: true,
            undoOverrides: !student,
            data,
          }),
        );
      }
  };

  const buttonHandler = () => {
    setSettingsModalState((prev) => {
      const newState = { ...prev, open: false };
      return newState;
    });
  };

  const adds = (label: string) => label + (student ? '-student' : '-class');

  if (classStateLoading)
    return (
      <Container>
        <Spinner label="Loading ..." center />
      </Container>
    );
  else
    return (
      <Container>
        {debugPrint && (
          <ul>
            <li>
              <Text variant="nav">
                All classes:{' '}
                {JSON.stringify(
                  allClassStates.map((cs) => ({
                    classId: cs.classId,
                    userStates: cs.userStates.filter((s) => s.name == 'language'),
                  })),
                )}
              </Text>
            </li>
            <li>
              <Text variant="nav">Class list: {classIds.join(', ')}</Text>
            </li>
          </ul>
        )}
        <Table>
          <tbody>
            <tr style={{ borderBottom: '1px solid black' }}>
              <td>
                <Text variant="md">Subtitles</Text>
              </td>
              <td>
                <ConfigDropdown data-cy={adds('live-classroom-subtitles-dropdown')} fullWidth={true}>
                  <ConfigDropdownIcon>
                    <SubtitlesIcon />
                  </ConfigDropdownIcon>
                  <DropdownMenu
                    itemArray={languageOptions.subtitles}
                    selectedItem={language.subtitles}
                    setSelectedItem={(x) => selectLanguage('subtitles', x)}
                  >
                    <ConfigDropdownText variant="md">
                      {language.subtitles ? language.subtitles.name : 'Subtitles'}
                    </ConfigDropdownText>
                  </DropdownMenu>
                </ConfigDropdown>
              </td>
            </tr>
            <tr>
              <td>
                <Text variant="md">Subtitle Font Size</Text>
              </td>
              <td>
                <ConfigDropdown data-cy={adds('live-classroom-accessibility-dropdown')} fullWidth={true}>
                  <ConfigDropdownIcon>
                    <FontSizeIcon />
                  </ConfigDropdownIcon>
                  <DropdownMenu
                    itemArray={accessibilityOptions}
                    selectedItem={accessibility}
                    setSelectedItem={selectAccessibility}
                  >
                    <ConfigDropdownText variant="md">
                      {accessibility ? accessibility.name : 'accessibility'}
                    </ConfigDropdownText>
                  </DropdownMenu>
                </ConfigDropdown>
              </td>
            </tr>
            <tr>
              <td>
                <Text variant="md">Spoken Language</Text>
              </td>
              <td>
                <ConfigDropdown data-cy={adds('live-classroom-spoken-dropdown')} fullWidth={true}>
                  <ConfigDropdownIcon>
                    <AudioIcon />
                  </ConfigDropdownIcon>
                  <DropdownMenu
                    itemArray={languageOptions.spoken}
                    selectedItem={language.spoken}
                    setSelectedItem={(x) => selectLanguage('spoken', x)}
                  >
                    <ConfigDropdownText variant="md">
                      {language.spoken ? language.spoken.name : 'Language'}
                    </ConfigDropdownText>
                  </DropdownMenu>
                </ConfigDropdown>
              </td>
            </tr>
            <tr>
              <td>
                <Text variant="md">Text Language</Text>
              </td>
              <td>
                <ConfigDropdown data-cy={adds('live-classroom-text-dropdown')} fullWidth={true}>
                  <ConfigDropdownIcon>
                    <LanguageIcon />
                  </ConfigDropdownIcon>
                  <DropdownMenu
                    itemArray={languageOptions.text}
                    selectedItem={language.text}
                    setSelectedItem={(x) => selectLanguage('text', x)}
                  >
                    <ConfigDropdownText variant="md">
                      {language.text ? language.text.name : 'Language'}
                    </ConfigDropdownText>
                  </DropdownMenu>
                </ConfigDropdown>
              </td>
            </tr>
          </tbody>
        </Table>
        <ButtonRow>
          <StyledButton style={{ marginRight: 'auto' }} onClick={doDefault}>
            {student ? `Set to Same as Class` : `Reset to Defaults`}
          </StyledButton>
          <StyledButton onClick={buttonHandler}>Done</StyledButton>
        </ButtonRow>
      </Container>
    );
};

const Container = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '5em',
  a: {
    cursor: 'pointer',
  },
  height: '22vh',
});

const ButtonRow = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  float: 'right',
  marginTop: '1rem',
});

const Table = styled.table({
  width: '100%',
});

const StyledButton = styled(Button)({
  display: 'flex',
  alignSelf: 'end',
});
