// @ts-strict-ignore
import React, { useEffect, useState, ChangeEvent } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import styled from '@emotion/styled';
import { chain, upperFirst, isArray, startCase, pick, max, isEmpty, isEqual, omit, sortBy } from 'lodash';
import { ModuleEvent } from '../../types/models';
import { Text } from './text';
import { evaluationColors } from './styles';
import { charcoal05, charcoal15, black, gray } from '../utils/colors';
import { dateToHHMM } from '../utils/time';
import { fixMangledModuleEvents } from '../utils/module';
import { MathJax } from 'better-react-mathjax';
import 'react-vertical-timeline-component/style.min.css';
import { ErrorPrints, ErrorMessage } from '../pages/liveClassroom/types';
import { Pagination, Stack } from '@mui/material';

interface Props {
  events: ModuleEvent[];
  errorList: ErrorPrints;
}

interface PrunedEvent {
  id: number;
  startTime: Date;
  endTime: Date | null;
  title: string;
  description: string | undefined | React.JSX.Element;
  action: string;
  evaluation: string | null;
}

type MultiSelectValue = string | number | { answer: string | number; expectedAnswer: string | number; html?: string };

const pruneRedundantEvents = (events: ModuleEvent[], errorPrints: { [id: string]: ErrorMessage }): PrunedEvent[] => {
  const debugPrint = false;
  const result: PrunedEvent[] = [];
  let initialTime: Date | undefined;
  let lastTime: Date | undefined;
  let lastTitle: string | undefined;
  let lastDescription: string | undefined | React.JSX.Element;
  let lastEvent: ModuleEvent | undefined;
  if (debugPrint) console.log(`*** starting with ${events.length} events`);

  // We want to show every event with an attempt at an answer
  const _nonCombinable = ['answer submitted', 'message sent', 'hint shown'];
  function eventsAreDuplicate(event1: ModuleEvent, event2: ModuleEvent): boolean {
    return (
      event1.action == event2.action &&
      !_nonCombinable.includes(event1.action) &&
      isEqual(omit(event1.properties, ['time']), omit(event2.properties, ['time']))
    );
  }
  for (const event of events) {
    // These are only used for marking "message sent" as received
    if (event.action == 'message read') continue;
    const title = getEventTitle(event, events);
    const description = getEventDescription(event, events, errorPrints);

    if (lastEvent && !eventsAreDuplicate(event, lastEvent)) {
      if (debugPrint) console.log(`*** adding ${event.action} ${title}`, description);
      result.push({
        id: lastEvent.id,
        startTime: initialTime,
        endTime: lastTime.getTime() > initialTime.getTime() + 30 * 1000 ? lastTime : null,
        title: lastTitle,
        description: lastDescription,
        action: lastEvent.action,
        // BvdS:  there may be "physical model" and "tool used" events with evaluation.
        // BvdS:  I changed this to simply look for the "evaluation" key.
        evaluation: 'evaluation' in lastEvent.properties ? lastEvent.properties.evaluation : null,
      });
      initialTime = event.createdAt;
    }
    if (!initialTime) initialTime = event.createdAt;
    lastTime = event.createdAt;
    lastTitle = title;
    lastDescription = description;
    lastEvent = event;
  }
  if (lastEvent) {
    if (debugPrint) console.log(`*** adding ${lastEvent.action} ${lastTitle}`, lastDescription);
    result.push({
      id: lastEvent.id,
      startTime: initialTime,
      endTime: lastTime.getTime() > initialTime.getTime() + 30 * 1000 ? lastTime : null,
      title: lastTitle,
      description: lastDescription,
      action: lastEvent.action,
      // BvdS:  there may be "physical model" and "tool used" events with evaluation.
      // BvdS:  I changed this to simply look for the "evaluation" key.
      evaluation: 'evaluation' in lastEvent.properties ? lastEvent.properties.evaluation : null,
    });
  }
  if (debugPrint) console.log(`*** ending with ${result.length} events`);
  return result;
};

const relevantActions = [
  'answer submitted',
  'hint shown',
  'tool used',
  'physical-model',
  'resume run',
  'message sent',
  'message read',
  'message-not-sent',
  'video skipped',
];

export const TaskTimeline = ({ events, errorList }: Props) => {
  const thisErrorPrints: { [id: string]: ErrorMessage } = {};
  const fixedEvents = fixMangledModuleEvents(events);
  const filteredEvents = fixedEvents.filter((e) => relevantActions.includes(e.action));

  // Sort by 'id' rather than a timestamp since there
  // may be ties (say, if event logging is batched).
  const eventsToShow = sortBy(pruneRedundantEvents(filteredEvents, thisErrorPrints), ['id']);
  function groupEvents(eventsToGroup: PrunedEvent[]): PrunedEvent[][] {
    let lastEvent: PrunedEvent | null = null;
    let lastGroup: PrunedEvent[] | null = null;
    const result: PrunedEvent[][] = [];
    for (const e of eventsToGroup) {
      // group by events next to this event, as well as if the action is equivalent
      if (lastEvent && lastEvent.action === e.action && e.action === 'answer submitted') {
        lastGroup.push(e);
      } else {
        lastGroup = [e];
        result.push(lastGroup);
      }
      lastEvent = e;
    }
    return result;
  }
  const groupedEvents = groupEvents(eventsToShow).reverse();
  const renderedEventGroups: React.JSX.Element[] = groupedEvents.map((eg, i) => (
    <RenderEventGroup key={i} eventGroup={eg} index={i} />
  ));

  // The "events" array is re-calculated every render,
  // so "events" itself won't work.
  const lastEventId = events.length > 0 ? events[events.length - 1].id : 0;
  useEffect(() => {
    /*
     * Only set errorPrints after rendering the component
     * Otherwise, we get
     *   Cannot update a component ... while rendering a different component
     */
    if (!isEmpty(thisErrorPrints)) {
      const [errorPrints, setErrorPrints] = errorList;
      setErrorPrints({ ...errorPrints, ...thisErrorPrints });
    }
  }, [lastEventId]);

  if (eventsToShow.length == 0) {
    return renderZeroState();
  } else {
    return <StyledVerticalTimeline layout="1-column"> {...renderedEventGroups}</StyledVerticalTimeline>;
  }
};

const renderZeroState = (): React.JSX.Element => (
  <Text variant="md" center>
    No events to show
  </Text>
);

interface EventProps {
  eventGroup: PrunedEvent[];
  index: number;
}

const RenderEventGroup = ({ eventGroup, index }: EventProps) => {
  // We need to store the current page for each instance
  // of the Pagination element.  For this, we Use the id
  // of the oldest event in the group:  this should
  // be stable as new events are added.
  const firstEventId = eventGroup[0].id;
  // Store the the page of the most recent event minus
  // the selected page.
  // That way, the most recent event stays on top
  // as new events are added.
  const [currentIndices, setCurrentIndices] = useState<{ [id: number]: number }>({});
  const currentIndex = firstEventId in currentIndices ? currentIndices[firstEventId] : 0;
  const selectedEvent = eventGroup[eventGroup.length - currentIndex - 1];

  const handleChange = (_: ChangeEvent, value: number) => {
    setCurrentIndices({ ...currentIndices, [firstEventId]: eventGroup.length - value });
  };

  if (eventGroup.length > 1) console.log(`** render for task ${eventGroup[0].id}, index ${index}`);

  return (
    <StyledVerticalTimelineElement
      position="left"
      className="vertical-timeline-element--work"
      contentStyle={{ background: charcoal05 }}
      contentArrowStyle={{ borderRight: `7px solid ${charcoal05}` }}
      key={index}
      date={
        selectedEvent &&
        dateToHHMM(selectedEvent.startTime) + (selectedEvent.endTime ? ' - ' + dateToHHMM(selectedEvent.endTime) : '')
      }
      icon={selectedEvent && getEventIcon(selectedEvent.action)}
      iconStyle={{ boxShadow: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Text color={selectedEvent && evaluationColors[selectedEvent.evaluation]} variant="nav">
        {selectedEvent?.title}
      </Text>
      {selectedEvent?.description && <Text variant="p">{selectedEvent?.description}</Text>}
      {eventGroup.length > 1 && (
        <Stack spacing={0}>
          <Pagination
            siblingCount={0}
            boundaryCount={1}
            count={eventGroup.length}
            page={eventGroup.length - currentIndex}
            onChange={handleChange}
            size={'small'}
          />
        </Stack>
      )}
    </StyledVerticalTimelineElement>
  );
};

const toolTexts = {
  graph3D: 'Used 3D Graph',
  interactiveGraph: 'Used Interactive Graph',
  replayAnimation: 'Replayed Animation',
  videoReplay: 'Replayed Video',
  audioReplay: 'Replayed Audio',
  stepAnimation: 'Used a Step Animation to solve for x',
};

const physicalModelTexts = {};

const getEventTitle = (event: ModuleEvent, events: ModuleEvent[]): string => {
  switch (event.action) {
    case 'answer submitted':
      const attempt = events.reduce(
        (total, e) =>
          total + (e.action === event.action && e.properties.evaluation != 'none' && e.id < event.id ? 1 : 0),
        1,
      );
      return `Attempt ${attempt}`;
    case 'physical-model':
      // Work-around for format error in spring 2024 logging
      const modelName = event.properties.modelName || event.properties.pmName;
      const title = physicalModelTexts[modelName] || modelName;
      return `${title}: ${event.properties.interfaceName}`;
    case 'hint shown':
      const index = chain(events)
        .filter((e) => e.action === event.action)
        .findIndex((e) => e.id === event.id)
        .value();
      return `Hint ${index + 1}`;
    case 'tool used':
      if (event.properties.toolType === 'equation-solver' && event.properties.evaluation === 'wrongForm') {
        return 'Used Equation Solver (Wrong Form)';
      }
      return toolTexts[event.properties.toolType] || `Used ${startCase(event.properties.toolType)}`;
    case 'resume run':
      return 'Run resumed';
    case 'message sent':
      if (event.properties.sender === 'teacher') return 'Message Sent';
      else if (event.properties.sender === 'student') return 'Student Message';
      else {
        console.warn('invalid sender');
        return 'Message';
      }
    case 'message-not-sent':
      return 'Message Failed';
    default:
      return upperFirst(event.action);
  }
};

const getEventDescription = (
  event: ModuleEvent,
  events: ModuleEvent[],
  errorPrints: { [id: string]: ErrorMessage },
): string | undefined | React.JSX.Element => {
  const query = new URLSearchParams(window.location.search);
  const debug = query.get('debug') ? true : false;
  try {
    switch (event.action) {
      case 'answer submitted':
        const tav: CanvasTextAlign = event.properties?.format?.['text-align'] || 'center';
        return <Answer textAlignValue={tav}>{getAnswerText(event)}</Answer>;
      case 'hint shown':
        return event.properties.title;
      case 'tool used':
        // Many tools have no description
        if (event.properties.toolType === 'equation-solver') {
          return <MathJax dynamic>{`\\[${event.properties.answer.latex}\\]`}</MathJax>;
        } else if (event.properties.toolType === 'graph point hover') {
          return `Point: ${event.properties.pointValue}`;
        } else if (event.properties.description) {
          // Generic case
          return event.properties.description;
        }
        break;
      case 'physical-model':
        if (event.properties.userHtml) {
          const answer = <Text variant="inherit" dangerouslySetInnerHTML={{ __html: event.properties.userHtml }} />;
          if (event.properties.evaluation == 'incorrect' && event.properties.expectedHtml) {
            const expected = (
              <Text variant="inherit" dangerouslySetInnerHTML={{ __html: event.properties.expectedHtml }} />
            );
            return (
              <Answer textAlignValue={tav}>
                {answer} (expected ${expected})
              </Answer>
            );
          } else return <Answer textAlignValue={tav}>{answer}</Answer>;
        } else if (event.properties.userValue) {
          let answer = formatAnswer(event.properties.userValue);
          if (event.properties.evaluation == 'incorrect' && event.properties.expectedValue)
            answer += ` (expected ${formatAnswer(event.properties.expectedAnswer)})`;
          return <Answer textAlignValue={tav}>{answer}</Answer>;
        } else if (event.properties.description) {
          const answer = event.properties.description;
          return <Answer textAlignValue={tav}>{answer}</Answer>;
        } else if (event.properties.answer) {
          let answer = '';
          for (const row in event.properties.answer) {
            // July 2024: not implemented yet.
            answer += JSON.stringify(row) + '\n';
          }
          return <Answer textAlignValue={tav}>{answer}</Answer>;
        }
        break;
      case 'message sent':
        const messageRead = events.find(
          (e) =>
            e.action === 'message read' && // Messages from the teacher
            (('timestamp' in e.properties &&
              (e.properties.timestamp === event.properties.timestamp ||
                // Old format, for backwards compatibility
                e.properties.timestamp === event.properties.time)) ||
              // Messages from the student
              ('id' in e.properties && e.properties.id == event.id)),
        );
        return (
          <>
            <div>{event.properties.message}</div>
            {messageRead && (
              <Text variant="sm" color={gray}>
                Read: {dateToHHMM(messageRead.createdAt)}
              </Text>
            )}
            {debug && <Text variant="nav">{JSON.stringify(pick(event, ['id', 'action', 'properties']))}</Text>}
            {debug &&
              (messageRead ? (
                <Text variant="nav">{JSON.stringify(pick(messageRead, ['id', 'action', 'properties']))}</Text>
              ) : (
                <Text variant="nav">
                  No message read match {JSON.stringify(events.filter((e) => e.action === 'message read'))}
                </Text>
              ))}
          </>
        );
      case 'message-not-sent':
        return <Text variant="not">{event.properties.message}</Text>;
      default:
        break;
    }
  } catch (error) {
    const ref = event.moduleId + ':' + event.taskId + ':' + event.action;
    const picks = ['moduleId', 'taskId', 'action', 'properties'];
    const message = `Invalid log message format for ${JSON.stringify(pick(event, picks))}:  ${error.message}`;
    errorPrints[ref] = { message };
  }
  return;
};

const formatAnswer = (ans: string | number): string => {
  return typeof ans == 'number' ? Number(ans).toLocaleString() : ans;
};

const getAnswerText = (event: ModuleEvent): string | React.JSX.Element => {
  const debugPrint = false;

  // Work-around for TRAN 2.7, 2.8, 2.9 logging bug, January 2023
  // Work-around for rigid-transformations bug, July 2024
  if (
    (event.moduleId === 'transformations' || event.moduleId === 'rigid-transformations') &&
    (event.taskId === 'formal-notation-circle-transformations' ||
      event.taskId === 'formal-notation-rectangle-transformations' ||
      event.taskId === 'formal-notation-triangle-transformations')
  ) {
    const text: string = event.properties.html || '';
    return <Text variant="inherit" dangerouslySetInnerHTML={{ __html: text.replace('\n', '<br>') }} />;
  }

  // Work around-for Similarity and Congruence task "aa-proof"
  // January 2023
  if (event.moduleId === 'similarity-and-congruence' && event.taskId === 'aa-proof') {
    const x = event.properties.answer;
    const a0 = x.length > 0 ? x[0].values[0] : '';
    const b0 = x.length > 0 ? x[0].values[1] : '';
    const a1 = x.length > 1 ? x[1].values[0] : '';
    const b1 = x.length > 1 ? x[1].values[1] : '';
    const b2 = x.length > 2 ? x[2].values[0] : '';
    // MathJax does not implement "tabular"
    return (
      <MathJax>{`\\[\\begin{array}{c|c}
      \\text{Statement}& \\text{Reason}\\\\
      \\hline
      \\angle TAR \\cong \\angle ${a0} & \\text{${b0}} \\\\
      \\angle ART \\cong \\angle ${a1}& \\text{${b1}} \\\\
      \\triangle ART \\sim \\triangle MRC & \\text{${b2}}
      \\end{array}
      \\]`}</MathJax>
    );
  }

  // Handle answers from specific tools
  if (
    event.properties.toolType == 'table' ||
    (isArray(event.properties.answer) && event.properties.answer.every((row) => isArray(row)))
  ) {
    // table answer
    return (
      <table>
        <tbody>
          {event.properties.answer.map((row: string[], rowIndex: number) => (
            <tr key={rowIndex}>
              {row.map((cell: string, colIndex) =>
                rowIndex === 0 ? <th key={colIndex}>{cell}</th> : <td key={colIndex}>{cell}</td>,
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else if (event.properties.toolType == 'multi-select') {
    // Multi-select tool
    const cols: number =
      max(event.properties.answer.map((row: { values: MultiSelectValue[] }) => row.values.length)) || 1;
    return (
      <table>
        {typeof event.properties.format == 'object' && 'tableHeaders' in event.properties.format && (
          <thead>
            <tr>
              <td></td>
              {event.properties.format.tableHeaders.map((x: string, index: number) => (
                <td key={index}>{x}</td>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {event.properties.answer.map(
            (
              row: { title: string; values: MultiSelectValue[]; evaluation: string; html?: string },
              rowIndex: number,
            ) => (
              <tr key={rowIndex}>
                <td>
                  <Text color={evaluationColors[row.evaluation]} variant="inherit">
                    {row.title}
                  </Text>
                </td>
                {row.html ? (
                  <td colSpan={cols}>
                    <Text variant="inherit" dangerouslySetInnerHTML={{ __html: row.html }} />
                  </td>
                ) : (
                  row.values.map((cell: MultiSelectValue, columnIndex: number) => (
                    <td key={columnIndex}>
                      {typeof cell === 'object' ? (
                        cell.html ? (
                          <Text variant="inherit" dangerouslySetInnerHTML={{ __html: cell.html }} />
                        ) : cell.answer == cell.expectedAnswer ? (
                          cell.answer
                        ) : (
                          `${cell.answer} (expected ${cell.expectedAnswer})`
                        )
                      ) : (
                        cell
                      )}
                    </td>
                  ))
                )}
              </tr>
            ),
          )}
        </tbody>
      </table>
    );
  } else if (event.properties.toolType == 'dynamic-answer') {
    // Dynamic answer tool:  student picks values for certain variables
    // Used in solids-of-rotation part 2, tasks 2 and 9
    if (Array.isArray(event.properties.answer))
      return (
        <ul>
          {event.properties.answer.map((row: { name: string; value: string | number }, rowIndex: number) => (
            <li key={rowIndex}>
              <Text variant="inherit">
                {row.name} = {row.value}
              </Text>
            </li>
          ))}
        </ul>
      );
    else return 'Error: Invalid log format for Dyamic Answer tool.';
  } else if (event.properties.toolType == 'multiple-select') {
    // Multiple selection tool
    if (event.properties.answer.length == 0)
      return (
        <Text variant="p" color={gray}>
          No answer submitted
        </Text>
      );
    else return event.properties.answer.join(', ');
  } else if (event.properties.toolType == 'equation-editor') {
    // Equation Editor
    if (debugPrint) console.log(event.properties.answer.user, event.properties.answer.latex);
    return <MathJax dynamic>{`\\[${event.properties.answer.latex}\\]`}</MathJax>;
  }

  // Old code (before 2022) that handled specific tasks
  if (
    event.taskId === 'connecting-growth-rate-to-graph-shape' ||
    event.taskId === 'extending-understanding-of-graph-shape'
  ) {
    // moduleId 'exponential'
    if (event.properties.evaluation === 'incorrect')
      return 'Flatter curve that represents a virus spreading less quickly';
    else return 'Steeper curve that represents a virus spreading more quickly';
  } else if (event.taskId === 'understanding-containment-strategies') {
    // moduleId 'exponential'
    const answerKey = {
      A: 'City A',
      B: 'City B',
      C: 'City C',
    };
    const epa = event.properties.answer;
    if (Array.isArray(epa) || typeof epa == 'string') {
      const answer: string[] = typeof epa == 'string' ? epa.split('') : epa;
      return (
        <table>
          <thead>
            <tr>
              <th>Slowest</th>
              <th>Medium</th>
              <th>Fastest</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {answer.map((a) => (
                <td key={a}>{answerKey[a]}</td>
              ))}
            </tr>
          </tbody>
        </table>
      );
    } else return 'Invalid log format for task understanding-containment-strategies';
  } else if (event.taskId === 'connecting-melting-rate-to-graph-shape') {
    // moduleId 'linear'
    if (event.properties.answer >= 0) return 'A line with a positive slope, illustrating no melting.';
    else if (event.properties.answer > -0.8)
      return 'A line with a shallower negative slope, illustrating a slower melting rate.';
    else return 'A line with a steeper negative slope, illustrating a faster melting rate.';
  } else if (event.taskId === 'understanding-melting-rate') {
    // moduleId 'linear'
    const epa = event.properties.answer;
    if (Array.isArray(epa) || typeof epa == 'string') {
      const answer: string[] = typeof epa == 'string' ? epa.split('') : epa;
      const answerKey = {
        A: 'Oil/Gas Drilling',
        B: 'Deforestation',
        C: 'Burning Fossil Fuels',
      };
      return (
        <table>
          <thead>
            <tr>
              <th>Fastest</th>
              <th>Medium</th>
              <th>Slowest</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {answer.map((a) => (
                <td key={a}>{answerKey[a]}</td>
              ))}
            </tr>
          </tbody>
        </table>
      );
    } else return 'Invalid log format for task understanding-melting-rate';
  } else if (event.taskId === 'connecting-perimeter-to-graph-shape') {
    // moduleId 'quadratic'
    if (event.properties.evaluation === 'incorrect')
      return 'A curve with a lower vertex representing a greenspace with a smaller maximum area';
    else return 'A curve with a higher vertex to represent a greenspace with a larger maximum area';
  } else if (event.taskId === 'graphing-flight-redirections') {
    // moduleId 'systems-of-equations'
    if (event.properties.answer > -0.6 && event.properties.answer < -0.4)
      return 'A parallel line illustrating two flight paths that will never collide';
    else return 'A non-parallel line illustrating two flight paths headed for a collision';
  } else if (event.taskId === 'interactive-scatterplot') {
    // moduleId 'statistics'
    return chain(event.properties.answer)
      .split(' ')
      .filter()
      .map((coord) => coord.split(':')[0])
      .join('\n')
      .value();
  }

  // Generic case
  if (event.properties.html) {
    return <Text variant="inherit" dangerouslySetInnerHTML={{ __html: event.properties.html }} />;
  } else if (typeof event.properties.answer == 'string' || typeof event.properties.answer == 'number') {
    if (debugPrint) console.log('*** generic tool ' + JSON.stringify(event.properties, null, 2));
    if (typeof event.properties.answer == 'string' && event.properties.answer.trim() == '')
      return (
        <Text variant="p" color={gray}>
          No answer submitted.
        </Text>
      );
    let answer = formatAnswer(event.properties.answer);

    if (event.properties.evaluation == 'incorrect' && event.properties.expectedAnswer)
      answer += ` (expected ${formatAnswer(event.properties.expectedAnswer)})`;
    return answer;
  }

  // everything else
  if (debugPrint) console.log('*** unknown tool ' + JSON.stringify(event.properties, null, 2));
  return 'Unknown tool'; // event.properties.answer;
};

const getEventIcon = (action: string): React.JSX.Element => {
  switch (action) {
    case 'answer submitted':
      return <img src="/assets/icons/proficiency-complete.png" />;
    case 'hint given':
      return <img src="/assets/icons/probing-complete.png" />;
    case 'tool used':
      return <img src="/assets/icons/exploration-complete.png" />;
    case 'message sent':
      return <img src="/assets/icons/context-complete.png" />;
    default:
      return null;
  }
};

const StyledVerticalTimeline = styled(VerticalTimeline)({
  '&:before': {
    backgroundColor: charcoal15,
  },
});

const StyledVerticalTimelineElement = styled(VerticalTimelineElement)({
  img: { width: '80%', height: '80%' },
  '.vertical-timeline-element-date': {
    fontFamily: 'Roboto, sans-serif',
  },
});

const Answer = styled.div<{ textAlignValue: CanvasTextAlign }>(
  {
    th: {
      verticalAlign: 'bottom',
      textAlign: 'center',
      color: black,
    },
  },
  ({ textAlignValue }) => ({
    td: {
      verticalAlign: 'top',
      textAlign: textAlignValue,
    },
  }),
);
