import {
  Array,
  Boolean,
  Record,
  String,
  Number,
  Static,
  Undefined,
  InstanceOf,
  Dictionary,
  Null,
  Unknown,
  Partial,
} from 'runtypes';
import { Route } from './utils';
import { TeacherRecord, TimingRecord } from './accountManagement';
import { UserKeyResponseRecord } from './user';

export const moduleRoutes = (): { [name: string]: Route } => ({
  postModuleEvent: {
    method: 'post',
    path: '/module/event',
    requestBody: PostModuleEventRequestRecord,
    responseData: PostModuleEventResponseRecord,
  },
  getClassModuleEvents: {
    method: 'get',
    path: '/class/:classId/module/:moduleId/events',
    requestBody: GetEventsRequestRecord,
    responseData: GetEventsResponseRecord,
  },
  getClassroomModuleEvents: {
    method: 'get',
    path: '/classroom/:classroomId/module/:moduleId/events',
    requestBody: GetEventsRequestRecord,
    responseData: GetEventsResponseRecord,
  },
  getClassEvents: {
    method: 'get',
    path: '/class/:classId/events',
    requestBody: GetEventsRequestRecord,
    responseData: GetEventsResponseRecord,
  },
  getClassroomEvents: {
    method: 'get',
    path: '/classroom/:classroomId/events',
    requestBody: GetEventsRequestRecord,
    responseData: GetEventsResponseRecord,
  },
  getLibrary: {
    method: 'get',
    path: '/library',
    requestBody: Record({}),
    responseData: GetLibraryResponseRecord,
  },
  getDefinition: {
    method: 'get',
    path: '/module/:moduleId/definition',
    requestBody: GetDefinitionRequestRecord,
    responseData: GetDefinitionResponseRecord,
  },
  getVersionDefinition: {
    method: 'get',
    path: '/version/:versionId/module/:moduleId/definition',
    requestBody: GetDefinitionRequestRecord,
    responseData: GetVersionDefinitionResponseRecord,
  },
  getDefinitions: {
    method: 'get',
    path: '/definition',
    requestBody: GetDefinitionsRequestRecord,
    responseData: GetDefinitionsResponseRecord,
  },
  getVersionDefinitions: {
    method: 'get',
    path: '/version/:versionId/definition',
    requestBody: GetDefinitionsRequestRecord,
    responseData: GetVersionDefinitionsResponseRecord,
  },
  getModuleUsage: {
    method: 'get',
    path: '/module-usage',
    requestBody: Record({}),
    responseData: GetModuleUsageResponseRecord,
  },
  getClassStudentUsage: {
    method: 'get',
    path: '/class/:classId/student-usage',
    requestBody: Record({}),
    responseData: GetStudentUsageResponseRecord,
  },
  getRecentErrorReports: {
    method: 'get',
    path: '/error-report',
    requestBody: Record({}),
    responseData: RecentModuleEventResponseRecord,
  },
});

const ModuleEventRecord = Record({
  sessionId: String,
  runId: String.Or(Null).Or(Undefined),
  moduleId: String,
  taskId: String,
  action: String,
  properties: Dictionary(Unknown).Or(Null).Or(Undefined),
});

const RecentModuleEventResponseRecord = Array(
  Record({
    sessionId: String,
    runId: String.Or(Null).Or(Undefined),
    properties: Dictionary(Unknown).Or(Null).Or(Undefined),
  }),
);

const ProgressRecord = Dictionary(Array(String.Or(Null)));
// eslint-disable-next-line @typescript-eslint/no-array-constructor
export const PostModuleEventRequestRecord = ModuleEventRecord.Or(Array(ModuleEventRecord)).Or(
  // January 2025 version format
  Record({
    sessionId: String,
    events: Array(
      Record({
        action: String,
        properties: Dictionary(Unknown),
      }),
    ),
  }),
);

export const PostModuleEventResponseRecord = Record({
  runId: String,
})
  .Or(
    // Login messages or set-class response
    Record({
      progress: ProgressRecord,
      // entire state associated with this student and class
      state: Dictionary(Unknown),
      // Server timestamp of most recent state update
      stateTime: InstanceOf(Date).Or(Null),
    }),
  )
  .Or(
    Record({
      token: String,
      userId: Number,
      loginEventId: Number,
      classes: UserKeyResponseRecord,
    }),
  )
  .Or(
    // backwards-compatibility for old login format
    ProgressRecord,
  );

export const GetEventsRequestRecord = Record({});
export const GetEventsResponseRecord = Record({
  events: Array(
    Record({
      id: Number,
      sessionId: String,
      version: String,
      moduleId: String,
      taskId: String,
      classId: Number,
      userId: Number,
      action: String,
      runId: String,
      properties: Dictionary(Unknown).Or(Null),
      createdAt: InstanceOf(Date),
    }),
  ),
});

export const SubjectRecord = Record({
  id: String,
  name: String,
  children: Array(String),
  parents: Array(String),
  modules: Array(String),
});
export const GetLibraryRequestRecord = Record({});
export const GetLibraryResponseRecord = Record({
  subjects: Array(SubjectRecord),
});

export const GetDefinitionRequestRecord = Record({});
const TaskDefinitionRecord = Record({
  id: String,
  name: String,
  description: String,
  question: String, // HTML format
  answer: String.Or(Number), // HTML format
  messages: Array(String), // plain text format
  images: Array(String), // File name
  skills: Array(String), // skill description
}).And(
  Partial({
    snapshot: Boolean, // Default (missing) is "true"
    sceneType: String,
  }),
);
// A single modulePart (the tutorial) is marked with "0"
const PartRecord = Record({
  modulePart: Number,
  tasks: Array(TaskDefinitionRecord),
}).And(
  Partial({
    image: String,
    implementationGuide: Record({
      url: String,
    }),
    video: Partial({
      file: String,
      url: String,
    }),
    quizPoints: Number,
    quizRecommendation: String,
    type: String,
  }),
);

const ModuleDefinitionRecord = Record({
  id: String,
  name: String,
  subjectId: String,
  parts: Array(PartRecord),
}).And(
  Partial({
    image: String,
    subtitle: String,
    alignedResourcesLink: String,
  }),
);
export const GetDefinitionResponseRecord = Record({
  module: ModuleDefinitionRecord,
});
export const GetVersionDefinitionResponseRecord = Record({
  module: ModuleDefinitionRecord,
  version: String,
});

export const VersionDefinitionRecord = Record({
  modules: Array(ModuleDefinitionRecord),
  features: Array(String),
});
export const GetDefinitionsRequestRecord = Record({});
export const GetDefinitionsResponseRecord = VersionDefinitionRecord;
export const GetVersionDefinitionsResponseRecord = VersionDefinitionRecord.And(
  Record({
    version: String,
  }),
);

const AggregateStatsRecord = Record({
  count: Number,
  mean: Number.Or(Null),
  median: Number.Or(Null),
  standardDeviation: Number.Or(Null),
});
const FinishedTasksRecord = Record({
  productName: String.Or(Null),
  contentIds: Array(String),
  // Subset of TaskDefinitionRecord
  tasks: Array(
    Record({
      id: String,
      modulePart: Number,
      number: Number,
    }),
  ),
  counts: Array(Number),
});
const HistogramRecord = Record({
  bin: Number,
  value: Number,
});
const TeacherStatisticsRecord = TeacherRecord.And(
  Record({
    classes: Array(Number),
    rosteredStudents: Number,
    medianTimeOnTask: Number.Or(Null),
    startedHistogram: Array(HistogramRecord),
    finishedHistogram: Array(HistogramRecord),
    institutionIds: Array(Number),
    institutionNames: Array(String),
  }),
);
export const GetAModuleUsageResponseRecord = Record({
  id: String,
  // There may be several products for a given
  // moduleId.  Thus, we let the server specify the appropriate
  // name and subject, depending on the data itself.
  name: String,
  subjectId: String,
  startedTimeOnTask: AggregateStatsRecord,
  completedTimeOnTask: AggregateStatsRecord,
  finishedTasksCount: Array(FinishedTasksRecord),
});
export const GetModuleUsageResponseRecord = Record({
  modules: Array(GetAModuleUsageResponseRecord),
  minTime: Number,
  registeredStudents: Number,
  activeStudents: Number,
  unlicensedStudents: Number,
  studentTimes: AggregateStatsRecord,
  studentTimesExclude: AggregateStatsRecord,
  studentModuleFractionsCompleted: AggregateStatsRecord,
  crossModule: Array(Array(Number)),
  crossModuleExclude: Array(Array(Number)),
  crossModuleBins: Number,
  teachers: Array(TeacherStatisticsRecord),
  plans: Array(Unknown), // in typescript, any[]
}).And(TimingRecord);

export const AStudentStatsRecord = Record({
  taskCount: Number,
  timeOnPart: Number,
  startedTasks: Number,
  completedTasks: Number,
});
export const GetAStudentModuleUsageRecord = Record({
  // Use 0 for module without parts (like the tutorial).
  moduleParts: Dictionary(AStudentStatsRecord),
});
export const GetAStudentUsageResponseRecord = Record({
  modules: Dictionary(GetAStudentModuleUsageRecord),
});
export const GetStudentUsageResponseRecord = Record({
  students: Dictionary(GetAStudentUsageResponseRecord),
});

export type PostModuleEventRequest = Static<typeof PostModuleEventRequestRecord>;
export type PostModuleEventResponse = Static<typeof PostModuleEventResponseRecord>;
export type GetEventsRequest = Static<typeof GetEventsRequestRecord>;
export type GetEventsResponse = Static<typeof GetEventsResponseRecord>;
export type Progress = Static<typeof ProgressRecord>;
export type GetDefinitionRequest = Static<typeof GetDefinitionRequestRecord>;
export type Part = Static<typeof PartRecord>;
// This should eventually be a literal in the PartRecord definition
export enum PartType {
  supplement = 'supplement',
}
export type AggregateStats = Static<typeof AggregateStatsRecord>;
export type FinishedTasks = Static<typeof FinishedTasksRecord>;
export type Subject = Static<typeof SubjectRecord>;
export type ModuleDefinition = Static<typeof ModuleDefinitionRecord>;
export type GetDefinitionResponse = Static<typeof GetDefinitionResponseRecord>;
export type GetDefinitionsRequest = Static<typeof GetDefinitionsRequestRecord>;
export type GetDefinitionsResponse = Static<typeof GetDefinitionsResponseRecord>;
export type VersionDefinition = Static<typeof VersionDefinitionRecord>;
export type GetVersionDefinitionsResponse = Static<typeof GetVersionDefinitionsResponseRecord>;
export type GetLibraryRequest = Static<typeof GetLibraryRequestRecord>;
export type GetLibraryResponse = Static<typeof GetLibraryResponseRecord>;
export type TaskDefinition = Static<typeof TaskDefinitionRecord>;
export type GetAModuleUsageResponse = Static<typeof GetAModuleUsageResponseRecord>;
export type GetModuleUsageResponse = Static<typeof GetModuleUsageResponseRecord>;
export type GetAStudentUsageResponse = Static<typeof GetAStudentUsageResponseRecord>;
export type GetStudentUsageResponse = Static<typeof GetStudentUsageResponseRecord>;
export type TeacherStatistics = Static<typeof TeacherStatisticsRecord>;
