import { ModuleEvent, ModuleEventWithUserClass } from '../../../types/models';
import { ModuleDefinition, Part, TaskDefinition } from '../../../types/routes/module';
import { Student } from '../../../types/routes/class';

export interface ActiveRun {
  selected: ModuleEventWithUserClass;
  last: ModuleEventWithUserClass;
}

export interface ClassroomModalState {
  open: boolean;
  classId?: number | null;
}

export interface MessageModalState {
  open: boolean;
  classIds?: number[];
  student?: UserWithClassId;
  module?: ModuleDefinition;
  task?: TaskDefinition;
}

export interface SettingsModalState {
  open: boolean;
  classIds?: number[];
  student?: UserWithClassId;
}
export interface ConfirmModalState {
  open: boolean;
  onConfirm?: () => void;
}

export interface TaskChecklistItem {
  title: string;
  titleHtml?: string;
  evaluation: 'in-progress' | 'locked' | 'completed';
  description: string;
  descriptionHtml?: string;
}

export interface TaskChecklist {
  title: string;
  titleHtml?: string;
  items: TaskChecklistItem[];
  createdAt: Date;
}

export interface TaskState {
  started: number;
  finished: number;
  count: number;
  checklist?: TaskChecklist;
}

export enum ScreencastStatus {
  none = 'none',
  success = 'success',
  error = 'error',
}

export interface Screencast {
  available: boolean;
  message?: string;
  requestId?: number;
  status: ScreencastStatus;
  url?: string;
}

export interface StudentStatus {
  idle: boolean;
  finishedTaskSet: Set<string>;
  started: boolean;
  // finished: boolean;
  unreadMessages: ModuleEvent[];
  isWatchAvailable: boolean;
  version: string;
  module: ModuleDefinition | null;
  part: Part | null;
  partIndex: number;
  activeTaskIndex: number;
  activeTaskLabel: string;
  totalPartTaskCount: number;
  displayName: string;
  displayClass?: string;
  overrides: Set<StudentOverrides>;
  headsetBatteryLevel?: number;
  leftControllerBatteryLevel?: number;
  rightControllerBatteryLevel?: number;
  screencast: Screencast;
}

export enum StudentOverrides {
  settings = 'settings',
  launch = 'launch',
  pause = 'pause',
}

export interface UserWithClassId extends Student {
  classId: number;
}

export interface ErrorMessage {
  title?: string;
  message: string;
  link?: string;
}
export type ErrorPrints = [{ [id: string]: ErrorMessage }, (x: { [id: string]: ErrorMessage }) => void];
