// @ts-strict-ignore
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { sortBy } from 'lodash';
import { ExportToCsv } from 'export-to-csv';
import styled from '@emotion/styled';

import { useAppDispatch, useAppSelector } from '../../redux';
import {
  get as getClass,
  create as createAction,
  edit as editAction,
  editNickName as editNickNameAction,
  getLearnerTypes,
  remove as removeAction,
  resetCreate,
  getExternal as getExternalClassList,
  updateExternal as updateExternalAction,
  resetExternalUpdate,
} from '../../redux/actions/class';
import { getClassContractsAction } from '../../redux/actions/accountManagement';
import { countLicensesLeft } from '../licenseManagement/util';

import { Button } from '../../components/button';
import { gray, primary, secondary, red, white } from '../../utils/colors';
import { PageContainer } from '../../components/pageContainer';
import { TextField } from '../../components/textField';
import { Header } from '../../components/header';
import { AddStudentModal } from './addStudentModal';
import { AddStudentCSVModal } from './addStudentCSVModal';
import { CustomTooltip as Tooltip } from '../../components/tooltip';

import { LeftNav } from '../../components/leftNav';
import XIcon from '../../assets/icons/x.svg';
import SummaryIcon from '../../assets/icons/summary.svg';
import ProgressIcon from '../../assets/icons/progress.svg';
import LiveIcon from '../../assets/icons/live.svg';
import EditIcon from '../../assets/icons/edit.svg';

import { Text } from '../../components/text';
import { ActionBar, AccessKey } from '../../components/styles';
import { formatName, sortOrder } from '../../utils/user';
import { Alert, ExtraAlertText } from '../../components/Alert';
import { Modal } from '../../components/modal';
import { Table, TableRow, TableCell, TableBody } from '@mui/material';
import { NewStudent, Student, StudentRecord, Teacher } from '../../../types/routes/class';
import { LicensesLeftAlert } from '../../components/LicensesLeftAlert';
import { LicenseCheck } from '../../components/LicenseCheck';
import { StudentTableHeader } from '../../components/classes/StudentTableHeader';
import { TeacherTableHeader } from '../../components/classes/TeacherTableHeader';
import { ExternalSource, InstitutionUserRole } from '../../../types/models';
import { configPublic } from '../../../config-public';
import { classDisplayName } from '../../utils/class';

interface CSVExportColumns {
  FirstName: string;
  LastName: string;
  username: string;
  password: string;
}

interface Props {
  createMode: boolean;
  editMode: boolean;
}

export const ClassDetail: React.FC<Props> = ({ createMode, editMode }: Props) => {
  const debugPrint = false;
  const { classId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fetchingClass = useAppSelector((state) => state.class.fetchingClass);
  const creatingClass = useAppSelector((state) => state.class.creatingClass);
  const hasError = useAppSelector((state) => state.class.hasError);
  const contractError = useAppSelector((state) => state.accountManagement.hasError);
  const errorMessage = useAppSelector((state) => state.class.errorMessage);
  const contractErrorMessage = useAppSelector((state) => state.accountManagement.errorMessage);
  const institutionRoles = useAppSelector((state) => state.user.institutionRoles);
  /*
   * Find any insitution where the user has the role "teacher"
   * The new class will be assigned to this institution.
   *
   * TODO:  If there is more than one matching institution,
   * there should be some UI that allows the user to select
   * the desired institution.
   */
  const institution = institutionRoles.find((x) => x.roles.includes(InstitutionUserRole.teacher));
  const institutionId = institution ? institution.institutionId : null;

  /*
   * Update Class Items
   */
  const externalClassList = useAppSelector((state) => state.class.externalClasses);
  const updatingExternalClasses = useAppSelector((state) => state.class.updatingExternalClasses);
  const externalSource = useAppSelector((state) => state.user.externalSource);
  const externalInstance = useAppSelector((state) => state.user.externalInstance);

  const thisClass = useAppSelector((state) => state.class.classes.find((c) => c.id === parseInt(classId)));
  const classContracts = useAppSelector((state) => state.accountManagement.classContracts[parseInt(classId)]);
  const [name, setName] = useState(thisClass ? classDisplayName(thisClass) : '');
  const [section, setSection] = useState(thisClass?.section || null);
  const [students, setStudents] = useState<(NewStudent | Student)[]>(thisClass?.students || []);
  const [teachers, setTeachers] = useState<Teacher[]>(thisClass?.teachers || []);
  const [licensesLeft, setLicensesLeft] = useState(null);
  const [externalUpdatesAllowed, setExternalUpdatesAllowed] = useState(false);
  const [showRename, setShowRename] = useState(false);

  useEffect(() => {
    if (classId && !thisClass && !fetchingClass && !hasError) {
      dispatch(getClass(parseInt(classId)));
    }
  }, []);

  useEffect(() => {
    if (thisClass && !fetchingClass && !hasError) {
      setName(classDisplayName(thisClass));
      setSection(thisClass.section);
      setStudents(sortBy<Student>(thisClass.students, sortOrder));
      setTeachers(sortBy<Teacher>(thisClass.teachers, sortOrder));

      dispatch(getLearnerTypes({ classId: parseInt(classId) }));
      dispatch(getClassContractsAction(parseInt(classId)));
      // Fetch external list to determine if updates should be disabled
      dispatch(getExternalClassList());
    }
  }, [thisClass]);

  useEffect(() => {
    setLicensesLeft(countLicensesLeft(classContracts));
  }, [classContracts]);

  useEffect(() => {
    if (!createMode) {
      setExternalUpdatesAllowed(
        thisClass &&
          ((thisClass.externalSource == externalSource &&
            thisClass.externalInstance == externalInstance &&
            externalClassList.some((x) => x.id === thisClass.externalId)) ||
            // Work-around to allow class update needed
            // for IMS Global OneRoster v1.2 CTS Rostering certification
            (thisClass.externalSource == 'oneRoster' && thisClass.externalInstance == 'imsValidation')),
      );
    }
  }, [externalClassList]);

  const [showAddStudentModal, setShowAddStudentModal] = useState(false);
  const [showAddStudentCSVModal, setShowAddStudentCSVModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showExternalUpdateSuccessAlert, setShowExternalUpdateSuccessAlert] = useState(false);
  const [externalUpdateRequest, setExternalUpdateRequest] = useState(false);

  const create = () => {
    if (name) {
      dispatch(createAction({ externalName: '', nickName: name, section, institutionId, students }));
      navigate('/class-list');
    }
  };
  const edit = () => {
    if (!thisClass) return;
    dispatch(
      editAction({
        id: thisClass.id,
        externalName: thisClass.externalName,
        nickName: name != thisClass.externalName && name != '' ? name : null,
        section,
        institutionId: thisClass.institutionId || institutionId,
        students,
      }),
    );
    navigate('/class/' + classId);
  };
  const editNickNameSaveClicked = () => {
    if (!thisClass) return;
    dispatch(
      editNickNameAction({
        id: thisClass.id,
        nickName: name != thisClass.externalName && name != '' ? name : null,
      }),
    );
    setShowRename(false);
  };
  const externalUpdate = () => {
    dispatch(updateExternalAction(parseInt(classId)));
    navigate('/class/' + classId);
  };
  const deleteClass = () => {
    if (!thisClass) return;
    dispatch(removeAction(thisClass.id));
    navigate('/class-list');
  };
  const exportCSV = () => {
    const data: CSVExportColumns[] = [];
    students.forEach((student) => {
      if ('key' in student)
        data.push({
          FirstName: student.firstName,
          LastName: student.lastName,
          username: student.email,
          password: student.key,
        });
    });
    const csvExporter = new ExportToCsv({
      filename: ((name ? name : '') + (section ? section : '')).replace('/s+/g', '-').slice(0, 50),
      useKeysAsHeaders: true,
    });
    csvExporter.generateCsv(data);
  };
  const printCards = () => {
    window.open('/print-cards/' + thisClass.id, '_blank', 'noreferrer');
  };
  const externalUpdateDisabledTooltipText = () => {
    const source = thisClass ? thisClass.externalSource && ExternalSource[thisClass.externalSource] : null;
    let loginTypeString = '';
    if (source === ExternalSource.lti) {
      if (thisClass.externalInstance.indexOf('canvas') != -1) loginTypeString = 'Canvas class';
      else loginTypeString = 'class';
      return `To update, log in from the same ${loginTypeString}.`;
    }
    if (source === ExternalSource.clever) {
      if (externalSource === ExternalSource.clever)
        return 'This class is no longer available from Clever, please contact your local Clever administrator.';
      else loginTypeString = 'Clever';
    } else if (source === ExternalSource.google) {
      if (externalSource === ExternalSource.google)
        return 'This class is no longer available from Google Classroom, please contact your local Google Classroom administrator.';
      else loginTypeString = 'Google';
    } else if (source === ExternalSource.oneRoster) {
      if (thisClass.externalInstance.indexOf('classLink') != -1) {
        loginTypeString = 'ClassLink';
      } else if (thisClass.externalInstance.indexOf('gwinnett') != -1) {
        loginTypeString = 'Gwinnett';
      } else {
        if (debugPrint) console.log(`Unknown OneRoster type ${thisClass.externalInstance}`);
        loginTypeString = 'the associated student roster system';
      }
    } else {
      if (debugPrint) console.log(`Unexpected external class type ${thisClass && thisClass.externalSource}`);
      return `Invalid class type ${
        thisClass && thisClass.externalSource
      }.  Please contact Prisms support.prismsvr.com.`;
    }
    return `To update this class, log in to this Teacher Dashboard with ${loginTypeString}.`;
  };
  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);
  const onSectionChange = (event: React.ChangeEvent<HTMLInputElement>) => setSection(event.target.value || null);

  // External Update Section
  useEffect(() => {
    if (updatingExternalClasses) {
      setExternalUpdateRequest(true);
    } else if (!updatingExternalClasses && externalUpdateRequest) {
      setShowExternalUpdateSuccessAlert(!hasError);
      setExternalUpdateRequest(false);
    }
  }, [updatingExternalClasses]);

  const UpdateButton = styled(Button)({
    marginLeft: '1rem',
    marginRight: '2rem',
    cursor: updatingExternalClasses ? 'not-allowed' : 'cursor',
  });

  return (
    <PageContainer header={<Header />} leftNav={<LeftNav currentClassId={parseInt(classId)} />}>
      {(thisClass || createMode) && (
        <>
          <ActionBar>
            <ButtonBar>
              {editMode && <StyledEditIcon />}
              <ActionBarText>
                <Text data-cy="class-create-create-class-button" variant="lg2" color={primary} letterSpacing={1}>
                  {createMode ? 'Create a Class' : editMode ? 'Edit Class' : name + (section ? ', ' + section : '')}
                </Text>
                {thisClass?.externalName && thisClass.externalName !== thisClass.nickName && (
                  <ActionBarHeaderSubtext variant="p" color={gray}>
                    {thisClass.externalName}
                  </ActionBarHeaderSubtext>
                )}
              </ActionBarText>
              {!editMode && (
                <ClassButtons>
                  <PrimaryCircleSvgButton id="live-classroom-button" to={`/class/${classId}/live`}>
                    <LiveIcon />
                  </PrimaryCircleSvgButton>
                  <Tooltip anchorSelect={'#live-classroom-button'}>
                    <Text variant="nav">Live Classroom</Text>
                  </Tooltip>
                  <CircleSvgButton id="summary-report-button" to={`/class/${classId}/summary`}>
                    <SummaryIcon />
                  </CircleSvgButton>
                  <Tooltip anchorSelect={'#summary-report-button'}>
                    <Text variant="nav">Class Summary Report</Text>
                  </Tooltip>
                  <CircleSvgButton id="progress-button" to={`/class/${classId}/progress`}>
                    <ProgressIcon />
                  </CircleSvgButton>
                  <Tooltip anchorSelect={'#progress-button'}>
                    <Text variant="nav">Class Progress</Text>
                  </Tooltip>
                </ClassButtons>
              )}
            </ButtonBar>
            <Actions>
              {editMode && !createMode && (
                <TextButton onClick={() => setShowDeleteModal(true)} variant="nav" color={primary}>
                  <XIcon />
                  Delete Class
                </TextButton>
              )}
              {editMode && (
                <Button data-cy="class-create-save-changes-button" onClick={thisClass ? edit : create}>
                  Save Changes
                </Button>
              )}
            </Actions>
          </ActionBar>
          <Alert
            isOpen={showExternalUpdateSuccessAlert}
            severity="success"
            title={`Success`}
            closeAlert={() => setShowExternalUpdateSuccessAlert(false)}
          >
            {'Classroom Update Successful'}
          </Alert>
          {!editMode &&
            (contractError ? (
              <Alert severity="error" title="License Usage Alert">
                {contractErrorMessage}
              </Alert>
            ) : (
              <LicensesLeftAlert licensesLeft={licensesLeft} />
            ))}
          {editMode && (
            <ClassForm>
              <StyledLabel variant="p">Class Name*</StyledLabel>
              <StyledInput
                data-cy="class-create-input-name"
                placeholder="Enter class name"
                value={name}
                onChange={onNameChange}
              ></StyledInput>
              <StyledLabel variant="p">Enter Section Number*</StyledLabel>
              <StyledInput
                data-cy="class-create-input-section-number"
                placeholder="Enter section number"
                value={section || ''}
                onChange={onSectionChange}
              ></StyledInput>
            </ClassForm>
          )}
          {!createMode && !editMode && (
            <>
              <ActionBar>
                <Actions>
                  {thisClass.externalSource == null && (
                    <TextButton onClick={() => navigate(`/class/${classId}/edit`)} variant="nav" color={primary}>
                      <EditIcon />
                      Edit Class
                    </TextButton>
                  )}
                  {thisClass.externalSource != null && (
                    <>
                      <div id="update-button-with-tooltip">
                        <UpdateButton
                          disabled={!externalUpdatesAllowed || updatingExternalClasses || showRename}
                          onClick={externalUpdate}
                        >
                          Update
                        </UpdateButton>
                      </div>
                      {(!externalUpdatesAllowed || updatingExternalClasses) && (
                        <Tooltip anchorSelect="#update-button-with-tooltip" place="bottom">
                          {externalUpdateDisabledTooltipText()}
                        </Tooltip>
                      )}
                      <TextButton
                        data-cy="class-rename-show-form-button"
                        onClick={() => setShowRename(!showRename)}
                        variant="nav"
                        color={primary}
                      >
                        Rename Class
                      </TextButton>
                      <TextButton onClick={() => setShowDeleteModal(true)} variant="nav" color={primary}>
                        <XIcon />
                        Delete Class
                      </TextButton>
                    </>
                  )}
                </Actions>
              </ActionBar>
              {thisClass.externalSource != null && showRename && (
                <ActionBar>
                  <ClassForm>
                    <StyledLabel variant="p">Class Name</StyledLabel>
                    <StyledInput
                      data-cy="class-rename-input-nickname"
                      placeholder="Enter class nickname"
                      value={name}
                      onChange={onNameChange}
                    ></StyledInput>
                    <ButtonBarInline>
                      <Button data-cy="class-rename-save-changes-button" onClick={editNickNameSaveClicked}>
                        Save Name
                      </Button>
                    </ButtonBarInline>
                  </ClassForm>
                </ActionBar>
              )}
            </>
          )}
          <UserListContainer>
            <ActionBar>
              <Text variant="md" letterSpacing={1}>
                Students
              </Text>
              {!editMode && (
                <ActionsPadRight>
                  <TextButton onClick={exportCSV} variant="nav" color={primary}>
                    Export CSV
                  </TextButton>
                  <TextButton onClick={printCards} variant="nav" color={primary}>
                    Print Login Cards
                  </TextButton>
                </ActionsPadRight>
              )}
            </ActionBar>
            <br />
            {students.length > 0 && (
              <Table data-cy="class-details-student-list">
                <StudentTableHeader />
                <TableBody>
                  {students.map((student, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">
                        <StudentName bold variant="nav">
                          {formatName(student)}
                        </StudentName>
                      </TableCell>
                      <TableCell align="left">
                        <AccessKey color={gray} variant="nav">
                          {student.email}
                        </AccessKey>
                      </TableCell>
                      <TableCell align="left">
                        {'key' in student && (
                          <AccessKey color={primary} variant="nav">
                            {student.key}
                          </AccessKey>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {StudentRecord.guard(student) && (
                          <LicenseCheck
                            classId={parseInt(classId)}
                            student={student}
                            contracts={classContracts}
                            clickable={false}
                          />
                        )}
                      </TableCell>
                      {editMode && (
                        <TableCell align="left">
                          <RemoveStudent
                            onClick={() => {
                              // August 2023:  The current node version doesn't know about toSpliced()
                              students.splice(index, 1);
                              setStudents([...students]);
                            }}
                          >
                            <XIcon />
                          </RemoveStudent>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </UserListContainer>
          {editMode && (
            <>
              <AddStudent
                data-cy="class-create-add-student-button"
                center
                variant="p"
                color={gray}
                onClick={() => setShowAddStudentModal(true)}
              >
                Click to add a student
              </AddStudent>
              <AddStudent
                data-cy="class-import-csv-button"
                center
                variant="p"
                color={gray}
                onClick={() => setShowAddStudentCSVModal(true)}
              >
                Click to import CSV
              </AddStudent>
            </>
          )}
          {teachers.length > 0 && (
            <UserListContainer>
              <br />
              <ActionBar>
                <Text variant="md" letterSpacing={1}>
                  Teachers
                </Text>
              </ActionBar>
              <br />
              <Table>
                <TeacherTableHeader />
                <TableBody>
                  {teachers.map((teacher, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">
                        <Text bold variant="nav">
                          {formatName(teacher)}
                        </Text>
                      </TableCell>
                      <TableCell align="left">
                        <AccessKey color={gray} variant="nav">
                          {teacher.email}
                        </AccessKey>
                      </TableCell>
                      <TableCell align="left">
                        {teacher.key && (
                          <AccessKey color={primary} variant="nav">
                            {teacher.key}
                          </AccessKey>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </UserListContainer>
          )}
          {showAddStudentModal && (
            <AddStudentModal
              closeModal={() => setShowAddStudentModal(false)}
              students={students}
              setStudents={setStudents}
            />
          )}
          {showAddStudentCSVModal && (
            <AddStudentCSVModal
              closeModal={() => setShowAddStudentCSVModal(false)}
              students={students}
              setStudents={setStudents}
            />
          )}
          <Modal
            isOpen={showDeleteModal}
            closeModal={() => setShowDeleteModal(false)}
            title="Are you sure you want to delete this class?"
            ctas={[
              { text: 'Confirm', onClick: () => deleteClass() },
              { text: 'Cancel', onClick: () => setShowDeleteModal(false), variant: 'cancel' },
            ]}
          />
          <Alert
            isOpen={createMode && hasError && !creatingClass && !fetchingClass}
            closeAlert={() => {
              dispatch(resetCreate());
            }}
            severity="error"
            title="Class Creation Error"
            blocking={true}
          >
            {'Class could not be created.  This could be due to a loss of network connection.  Please send a screenshot to ' +
              configPublic.supportEmail.staff +
              '.'}
            {errorMessage && <ExtraAlertText>Error message: {errorMessage}</ExtraAlertText>}
          </Alert>
          <Alert
            isOpen={externalUpdatesAllowed && hasError && !creatingClass && !fetchingClass}
            closeAlert={() => {
              dispatch(resetExternalUpdate());
            }}
            severity="error"
            title="Error Retrieving or Editing Class Data"
            blocking={true}
          >
            {'Class data could not be retrieved or edited.  This could be due to a loss of network connection.  Please send a screenshot to ' +
              configPublic.supportEmail.staff +
              '.'}
            {errorMessage && <ExtraAlertText>Error message: {errorMessage}</ExtraAlertText>}
          </Alert>
        </>
      )}
    </PageContainer>
  );
};

const StyledEditIcon = styled(EditIcon)({
  marginRight: '1rem',
  color: primary,
});

const Actions = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const ActionsPadRight = styled(Actions)({
  marginRight: '2rem',
});

const TextButton = styled(Text)({
  marginLeft: '2rem',
  marginRight: '2rem',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',

  svg: {
    marginRight: '1rem',
  },
});

const ActionBarText = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  marginRight: '0.5rem',
});
const ActionBarHeaderSubtext = styled(Text)({
  marginTop: '0.5rem',
});

const ClassForm = styled.form({
  padding: '1rem',
  marginBottom: '2rem',
  width: '50%',
  paddingRight: '9.375rem',
  boxSizing: 'border-box',
});

const StyledLabel = styled(Text)({
  marginBottom: '.5rem',
});

const StyledInput = styled(TextField)({
  marginBottom: '1rem',
});

const UserListContainer = styled.div({
  padding: '1rem',
});

const AddStudent = styled(Text)({
  border: `1px dashed ${gray}`,
  padding: '2rem 0',
  borderRadius: 3,
  margin: '1rem 0',
  cursor: 'pointer',
  transition: 'color .3s, border-color .3s',

  '&:hover': {
    color: primary,
    borderColor: primary,
  },
});

const ClassButtons = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: '20 vw',
});

const StudentName = styled(Text)({
  marginRight: '2rem',
});

const RemoveStudent = styled.div({
  fontWeight: 600,
  cursor: 'pointer',
  backgroundColor: red,
  color: white,
  borderRadius: '100%',
  width: '2rem',
  height: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ButtonBar = styled.div({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  paddingBottom: 0,
  bottom: 0,
  left: 0,
  marginLeft: '1rem',
});

const ButtonBarInline = styled(ButtonBar)({
  marginLeft: 0,
});

const CircleSvgButton = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '3rem',
  height: '3rem',
  borderRadius: '3rem',
  border: `2px solid ${primary}`,
  margin: 0,
  marginLeft: '.5rem',
  color: primary,
});

const PrimaryCircleSvgButton = styled(CircleSvgButton)({
  width: '3.25rem',
  height: '3.25rem',
  color: white,
  border: 0,
  backgroundImage: `linear-gradient(206deg, ${secondary} 0%, ${primary} 95%)`,
  '&:hover': {
    backgroundImage: `linear-gradient(206deg, ${secondary} 25%, ${primary} 75%)`,
  },
});
