// @ts-strict-ignore
import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { fromPairs, pick } from 'lodash';
import { gray, grayCC } from '../utils/colors';
import { useAppSelector, useAppDispatch } from '../redux';
import { ModuleEvent, ModuleEventWithUserClass } from '../../types/models';
import { Text } from './text';
import { TaskDefinition } from '../../types/routes/module';
import { Timeline } from '../pages/liveClassroom/timeline';
import { CompletionCircle } from '../pages/liveClassroom/completionCircle';
import { TaskContent } from './TaskModal';
import { TaskTimeline } from './TaskTimeline';
import { formatName } from '../utils/user';
import { Student } from '../../types/routes/class';
import {
  TaskState,
  MessageModalState,
  SettingsModalState,
  ActiveRun,
  ErrorPrints,
  StudentStatus,
  TaskChecklist,
} from '../pages/liveClassroom/types';
import { getLastEvent, getStudentStatus, getRunName, PROGRESS_EVENTS } from '../pages/liveClassroom/event-util';
import { markMessagesAsRead, getDefinitions } from '../redux/actions/module';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Spinner } from './Spinner';

interface Props<T, U extends Student> {
  student: U;
  events: ModuleEventWithUserClass[];
  activeRun: ActiveRun | null;
  setActiveRun: (runEvent: ActiveRun) => void;
  setMessageModalState?: React.Dispatch<React.SetStateAction<MessageModalState>>;
  setSettingsModalState?: (state: SettingsModalState) => void;
  setTaskSelectorState?: T;
  hideScrollbar?: boolean;
  forceTimelineTaskSelect?: TaskDefinition | null;
  expectEvents?: boolean;
  errorList: ErrorPrints;
  HeaderRow?: (props: {
    student: U;
    lastEvent?: ModuleEventWithUserClass;
    studentStatus?: StudentStatus;
    setMessageModalState?: React.Dispatch<React.SetStateAction<MessageModalState>>;
    setSettingsModalState: (state: SettingsModalState) => void;
    setTaskSelectorState: T;
    errorList: ErrorPrints;
  }) => JSX.Element;
}

export const StudentInformation = <T, U extends Student>({
  student,
  events,
  activeRun,
  setActiveRun,
  setMessageModalState,
  setSettingsModalState,
  setTaskSelectorState,
  forceTimelineTaskSelect,
  HeaderRow,
  expectEvents,
  errorList,
  ...rest
}: Props<T, U>) => {
  const modules = useAppSelector((state) => state.module.definitions);
  const fetchingDefinitions = useAppSelector((state) => state.module.fetchingDefinitions);
  const lastEvent = getLastEvent(events, PROGRESS_EVENTS);

  useEffect(() => {
    if (!fetchingDefinitions && Object.keys(modules).length === 0) dispatch(getDefinitions());
  }, []);
  const moduleVersionDefinitions = useAppSelector((state) => state.module.versionDefinitions);
  const [activeTask, setActiveTask] = useState<TaskDefinition | null>(null);
  const [activeTaskManualOverride, setActiveTaskManualOverride] = useState<boolean>(false);
  const [readMessages, setReadMessages] = useState<ModuleEvent[]>([]);
  const [moduleState, setModuleState] = useState<{ [taskId: string]: TaskState }>({});

  const query = new URLSearchParams(window.location.search);
  const debug = query.get('debug') ? true : false;

  const taskRef = useRef(undefined);
  const dispatch = useAppDispatch();
  useEffect(() => {
    // Reset when switching students to re-enable dynamic behavior.
    // This override blocks activeTask from updating dynamically when new events come in.
    !forceTimelineTaskSelect && setActiveTaskManualOverride(false);
  }, [student.id]);

  useEffect(() => {
    /* Set to most the most recent run for a new student, a new module,
     * new run, or new task.
     */
    if (
      lastEvent &&
      (!activeRun ||
        activeRun.last.moduleId != lastEvent.moduleId ||
        activeRun.last.taskId != lastEvent.taskId ||
        activeRun.last.runId != lastEvent.runId)
    )
      setActiveRun({ selected: lastEvent, last: lastEvent });
  }, [lastEvent]);

  const thisRunEvents =
    lastEvent && activeRun
      ? events.filter((e) => e.moduleId == lastEvent.moduleId && e.runId == activeRun.selected.runId)
      : [];
  const status = getStudentStatus(
    student,
    activeRun?.selected || null,
    events,
    moduleVersionDefinitions,
    errorList,
    debug ? 0 : null,
  );

  useEffect(() => {
    if (status.part != null) {
      const newModuleState: { [taskId: string]: TaskState } = fromPairs(
        status.part.tasks.map((task) => [task.id, { started: 0, finished: 0, count: 1 }]),
      );
      const latestChecklistsByTask: { [taskId: string]: TaskChecklist } = {};
      for (const event of thisRunEvents) {
        if (event.taskId in newModuleState) {
          // mark the task as started/finished
          if (event.action == 'started') newModuleState[event.taskId].started = 1;
          else if (event.action == 'finished') newModuleState[event.taskId].finished = 1;

          // get the most up-to-date task checklist
          if (
            event.action === 'scene' &&
            (event.properties.action === 'show' || event.properties.action === 'update') &&
            event.properties.sceneObject &&
            event.properties.sceneObject === 'checklist' &&
            (!latestChecklistsByTask[event.taskId] || event.createdAt > latestChecklistsByTask[event.taskId].createdAt)
          ) {
            latestChecklistsByTask[event.taskId] = {
              title: event.properties.title,
              items: event.properties.items.map((i) => {
                return {
                  title: i.title,
                  evaluation: i.evaluation,
                  description: i.html || i.text,
                };
              }),
              createdAt: event.createdAt,
            };
          }
        }
      }
      for (const taskId in newModuleState) newModuleState[taskId].checklist = latestChecklistsByTask[taskId];
      const act = status.part.tasks.find(
        (task: TaskDefinition) => newModuleState[task.id].started && !newModuleState[task.id].finished,
      );
      if (setMessageModalState) {
        setMessageModalState((prev) => {
          return { ...prev, task: act };
        });
      }
      if (!activeTaskManualOverride && !forceTimelineTaskSelect) {
        setActiveTask(act);
      }
      setModuleState(newModuleState);
    } else {
      setActiveTask(null);
      setModuleState({});
    }

    if (forceTimelineTaskSelect) {
      setActiveTaskManualOverride(true);
      setActiveTask(forceTimelineTaskSelect);
    }
  }, [activeRun, status.part, lastEvent]);

  // only show runs for this module and part
  const runs: { [runId: string]: ModuleEventWithUserClass } = {};
  if (status.part != null && lastEvent) {
    for (const event of events) {
      // Only include runs where there are actual events.
      if (
        event.moduleId == lastEvent.moduleId &&
        status.part?.tasks.some((task) => task.id == event.taskId) &&
        PROGRESS_EVENTS.includes(event.action)
      )
        runs[event.runId] = event;
    }
  }

  useEffect(() => {
    const newReadMessages = status.unreadMessages.filter((event) => !readMessages.some((ev) => ev.id == event.id));
    if (newReadMessages.length > 0) {
      // Ensure we set visible task to task associated with last unread message.
      const act = status.part.tasks.find(
        (task: TaskDefinition) => task.id == newReadMessages[newReadMessages.length - 1].taskId,
      );
      setActiveTaskManualOverride(true);
      setActiveTask(act);

      setReadMessages([...readMessages, ...newReadMessages]);
      /*
       * Set a delay so that the icon persists for a bit
       *
       * The timeout matches the polling rate for logging messages.
       */
      setTimeout((data) => dispatch(markMessagesAsRead(data)), 5000, {
        readMessages: newReadMessages,
        sessionId: lastEvent.sessionId,
        runId: activeRun?.selected.runId || null,
      });
    }
  }, [student.id, status.unreadMessages]);

  return (
    <Container {...rest}>
      {debug && (
        <>
          <Text variant="nav">
            user id {student.id}, version {lastEvent ? lastEvent.version : '-'}{' '}
          </Text>
          <ul>
            <li>
              <Text variant="nav">
                current session id {events.length > 0 ? events[events.length - 1].sessionId : '-'}
              </Text>
            </li>
            <li>
              <Text variant="nav">
                last module {activeRun?.last.moduleId || 'none'}, run {activeRun?.last.runId || '<none>'}
              </Text>
            </li>
            <li>
              <Text variant="nav">
                selected module {activeRun?.selected.moduleId || 'none'}, run {activeRun?.selected.runId || '<none>'},
                events {thisRunEvents.length}, tasks {status.part?.tasks.length || '<none>'}
              </Text>
            </li>
            <li>
              <Text variant="nav">lastEvent {lastEvent ? JSON.stringify(lastEvent) : '-'}</Text>
            </li>
            <li>
              <Text variant="nav">activeRun {activeRun ? JSON.stringify(activeRun) : '-'}</Text>
            </li>
            <li>
              <Text variant="nav">current run events</Text>
              <ul>
                {!status.part &&
                  thisRunEvents.map((e) => (
                    <li key={e.id}>
                      <Text variant="nav">event {JSON.stringify(pick(e, ['moduleId', 'taskId', 'action']))}</Text>
                    </li>
                  ))}
              </ul>
            </li>
            <li>
              <Text variant="nav">screencast {JSON.stringify(status.screencast)}</Text>
            </li>
          </ul>
        </>
      )}
      <Name variant="lg2" color={gray} data-cy="StudentInformation-student-name">
        {formatName(student)}
      </Name>
      <Name variant="nav" color={gray} className={status.displayClass}>
        {status.displayName}
      </Name>

      <Header>
        {lastEvent && Object.keys(runs).length > 1 && (
          <StyledSelect
            id="run"
            onChange={(e) => setActiveRun({ selected: runs[e.target.value as string], last: activeRun.last })}
            value={activeRun.selected.runId}
          >
            {Object.values(runs).map((e, index) => (
              <MenuItem key={e.runId} value={e.runId}>
                <Text variant="md">{getRunName({ tasks: status.part?.tasks || [], events, lastEvent: e, index })}</Text>
              </MenuItem>
            ))}
          </StyledSelect>
        )}
        {HeaderRow && (
          <HeaderRow
            student={student}
            setSettingsModalState={setSettingsModalState}
            setTaskSelectorState={setTaskSelectorState}
            errorList={errorList}
            studentStatus={status}
            setMessageModalState={setMessageModalState}
            lastEvent={lastEvent}
          />
        )}
      </Header>

      {lastEvent && status.part != null ? (
        <>
          <TimelineContainer>
            {status.part && status.part.tasks.length > 0 && (
              <StyledCompletionCircle
                completionPercentage={(status.finishedTaskSet.size / status.part.tasks.length) * 100.0}
                text="Tasks Completed"
              />
            )}
            {status.part && status.part.tasks.length === Object.keys(moduleState).length && (
              <Timeline
                moduleId={status.module?.id || null}
                studentId={student.id}
                tasks={status.part.tasks}
                moduleState={moduleState}
                activeTask={activeTask}
                onTaskClick={(e) => {
                  setActiveTaskManualOverride(true);
                  setActiveTask(e);
                  if (setMessageModalState)
                    setMessageModalState((prev) => {
                      const newState = { ...prev, task: e };
                      return newState;
                    });
                }}
                taskRef={taskRef}
              />
            )}
          </TimelineContainer>
          {activeTask && (
            <SideBySide data-cy="StudentInformation-task-container">
              <TaskContent
                moduleId={status.module?.id || null}
                task={activeTask}
                wholeClass={!student}
                state={moduleState[activeTask.id]}
              />
              <StyledTaskTimeline
                events={thisRunEvents.filter((e) => e.taskId === activeTask.id)}
                errorList={errorList}
              />
            </SideBySide>
          )}
        </>
      ) : (
        <>
          {expectEvents && !lastEvent && <Spinner label="Fetching student data ..." center />}
          {!expectEvents && !lastEvent && (
            <Name variant="md" color={gray}>
              No events to show
            </Name>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div<{ hideScrollbar?: boolean }>(
  {
    padding: '0.7rem 1.5rem',
    borderLeft: `1px solid ${grayCC}`,
    width: '100%',
    boxSizing: 'border-box',
    display: 'block',
    minHeight: '10rem',
    maxHeight: '100vh',
  },
  ({ hideScrollbar }) => ({ overflowY: hideScrollbar ? 'visible' : 'scroll' }),
);

const Header = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1rem',
  background: '#efefef',
  padding: '0.4rem',
  borderRadius: '1rem',
});

const StyledSelect = styled(Select)({
  minWidth: '12.5rem',
  fontSize: '1rem',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  padding: '0',
});

const Name = styled(Text)({
  width: '100%',
  textAlign: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  marginBottom: '1rem',
});

const TimelineContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const SideBySide = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'top',
});

const StyledCompletionCircle = styled(CompletionCircle)({
  flexShrink: 0,
  marginRight: '1rem',
});

const StyledTaskTimeline = styled(TaskTimeline)({
  margin: '1rem 0',
});
